import React from 'react';
import logo from './pp3-logo.png';

function Header() {
  return (
    <header className="bg-white shadow-md py-4 sm:py-6">
      {/* <div className="container mx-auto flex justify-center px-4 sm:px-6">
        <img src={logo} className="h-20 sm:h-24" alt="logo" />
      </div> */}
      <h3 className="text-center text-xl sm:text-4xl font-Satisfy font-semibold mt-2 sm:mt-4">
        Kanaka Baby Shower
      </h3>
    </header>
  );
}

export default Header;
