import React from 'react';
import MainContent from './MainContent';
import ImageConverter from './ImageConverter';

function HomePage() {
  return (
    <>
      
      <MainContent />
      <ImageConverter />
      
    </>
  );
}

export default HomePage;