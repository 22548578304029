import React from 'react';

function LegalPage() {
  return (
    <div className="help-page font-Salsa">
      <div className='container mx-auto my-8 p-4 sm:p-6 bg-white shadow-xl rounded-xl transition-all duration-500 ease-in-out'>
      <h4 className="text-center text-xl sm:text-xl font-semibold mb-3 sm:mb-4 text-gray-800">Legal Information</h4>
        

      <section>
            <h3 class="text-xl font-bold mt-5">Introduction</h3>
            <p>Welcome to ShowMy.Pics Legal page. This section outlines the terms and conditions that govern your use of our AI Face Detection services and website. By accessing our website and using our services, you agree to comply with these terms.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Terms of Use</h3>
            <p><strong>Service Description</strong>: ShowMy.Pics provides AI-powered face detection services. These services are described in detail on our website.</p>
            <p><strong>User Responsibility</strong>: Users are responsible for any activity that occurs under their account and must use our services legally and ethically.</p>
            <p><strong>Prohibited Uses</strong>: Using the service for unlawful purposes, infringing on intellectual property, or engaging in harmful or fraudulent activities is strictly prohibited.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Privacy Policy</h3>
            <p><strong>Data Collection and Use</strong>: We collect data necessary for providing and improving our services. This includes, but is not limited to, user-submitted images and usage data.</p>
            <p><strong>Data Security</strong>: We employ robust security measures to protect your data, in line with industry standards.</p>
            <p><strong>Third-Party Sharing</strong>: We do not share your personal data with third parties except as necessary to provide our services or as required by law.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Intellectual Property</h3>
            <p><strong>Ownership</strong>: All content on this website, including text, graphics, logos, and software, is the property of ShowMy.Pics or its suppliers and protected by copyright and other intellectual property laws.</p>
            <p><strong>Usage Restrictions</strong>: Unauthorized use, reproduction, or modification of any content on this website is strictly prohibited.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Disclaimers</h3>
            <p><strong>Service 'As Is'</strong>: Our AI face detection services are provided "as is" without any guarantees of accuracy or completeness.</p>
            <p><strong>No Warranties</strong>: We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Limitation of Liability</h3>
            <p><strong>Extent of Liability</strong>: ShowMy.Pics shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from the use of our services.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Governing Law</h3>
            <p><strong>Jurisdiction</strong>: These terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Changes to Legal Terms</h3>
            <p><strong>Updates</strong>: We reserve the right to modify these legal terms at any time. Your continued use of the website and services following any such change constitutes your agreement to follow and be bound by the terms as modified.</p>
        </section>

        <section>
            <h3 class="text-xl font-bold mt-5">Contact Information</h3>
            <p>For any questions regarding these legal terms, please contact us at hello@peenak.com.</p>
        </section>

      
    </div>
    </div>
  );
}

export default LegalPage;
