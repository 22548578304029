import React from 'react';

function MainContent() {
  return (
    <div className='container mx-auto my-8 p-4 sm:p-6 bg-white shadow-xl rounded-xl transition-all duration-500 ease-in-out'>
      <h4 className="text-center text-xl sm:text-2xl font-Salsa font-semibold mb-3 sm:mb-4 text-gray-800">Welcome Aboard</h4>
      <p className="text-center text-sm sm:text-base font-Salsa text-gray-600 mb-4 sm:mb-6">Welcome to ShowMy.Pics  Face Search! Upload a photo, and our AI will find you at the baby shower. Relive the joyous moments effortlessly! 🍼👶💕</p>
    </div>
  );
}

export default MainContent;
